<template>
  <page-main
    :title="`Your accomplishments at ${companyName}`"
    nextText="Done? Go back to the experience overview."
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/Work')"
    @save="save()"
  >
    <template v-slot:aside>
      Accomplishments are important - they showcase the impact you made in your
      previous role(s) and what you can bring to your prospective new employer.
      <ul>
        <li>
          Start by thinking about the accomplishments you achieved in this role
          that you are the proudest of or you think are the most important. This
          can include projects, initiatives, actions, results, and training.
        </li>
        <li>
          Accomplishments should be specific to you and a particular experience
          - use examples that focus on your participation as best as you can.
        </li>
        <li>
          Resumation Plus users can use AI to optimize your inputs! Simply
          select the toggle to optimize with AI, and if you want to edit the AI
          phrasing, you can!
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Examples:</span>
            <ul>
              <li>Launched a website for customer feedback.</li>
              <li>Reduced cost by redesigning machines.</li>
              <li>Developed a marketing plan for Brand A.</li>
              <li>Fixed emergency bug for Product A.</li>
              <li>I learned how to write code.</li>
              <li>Led a team to grow Product A revenue.</li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel">
            <r-expansion-panel v-for="(item, i) in achievements" :key="i">
              <a class="anchor" :id="'panel-' + i"></a>
              <r-expansion-panel-header>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ buildTitleString(item) }}
                  </div>
                  <div class="pr-3 pl-2">
                    <v-btn
                      outlined
                      color="white"
                      @click.stop="
                        launchDialog(item.id, buildTitleString(item))
                      "
                      >Remove</v-btn
                    >
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row class="pt-4">
                    <v-col cols="12" class="r-col">
                      <p class="text-body-1 font-italic text--secondary">
                        The questions below are designed to help you present
                        your accomplishment in a way that clear and interesting
                        to decision makers reviewing your resume.
                      </p>
                      <p class="text-body-1 font-italic text--secondary">
                        Be prepared to further expand on these accomplishments
                        during an interview.
                      </p>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.background"
                        rows="1"
                        autogrow
                        label="What is the background of this accomplishment? (One sentence)."
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.responsibility"
                        rows="1"
                        autogrow
                        label="What was your role or responsibility? (One sentence)."
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.actionResult"
                        rows="1"
                        autogrow
                        label="What action did you take, or what was the result? (One or two sentences)"
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.measurement"
                        rows="1"
                        autogrow
                        label="Describe the measured dollar amount, quantity, percent increase / decrease, or other measurement related to the outcome. (If applicable)"
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="pl-5">
                      <h3>Suggested Phrasing:</h3>
                      <p class="text-body-1">{{ item.displayAuto }}</p>
                      <v-switch
                        v-model="item.useCustom"
                        label="Need to optimize with AI or make an adjustment?"
                      ></v-switch>
                      <transition name="line-66">
                        <div>
                          <v-textarea
                            v-if="item.useCustom"
                            v-model="item.displayCustom"
                            rows="3"
                            auto-grow
                            label="Adjust the phrase"
                          ></v-textarea>
                          <ai-optimize-phrasing
                            v-if="item.useCustom"
                            :phrasing="item.displayCustom"
                            context="work-accomplishment"
                            @onAcceptPhrasing="
                              (newPhrasing) =>
                                acceptAiSuggestion(item, newPhrasing)
                            "
                          ></ai-optimize-phrasing>
                        </div>
                      </transition>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
        <v-col cols="12" class="r-col d-flex justify-center">
          <v-btn outlined @click="addAchievement()"
            >Add New Accomplishment</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <r-dialog
      v-model="dialog"
      title="You are about to remove this accomplishment:"
    >
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialog()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import AiOptimizePhrasing from "../components/AiOptimizePhrasing";
import * as uuid from "../utility/guid";
import * as disp from "../utility/display";
import * as log from "../utility/logging/logger";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "WorkAccomplishments",
  components: {
    "ai-optimize-phrasing": AiOptimizePhrasing,
  },
  data: () => ({
    panel: 0,
    dialog: false,
    dialogId: "",
    dialogDisplay: "",
    interval: null,
  }),
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }

    if (this.achievements.length == 0) {
      this.addAchievement();
    }

    // set up auto-save 30s interval
    this.interval = setInterval(() => this.saveWork(), 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    achievements: {
      deep: true,
      handler() {
        this.achievements.forEach((a) => {
          let setCustomToAuto =
            disp.IsNullorWhitespace(a.displayCustom) ||
            a.displayCustom === a.displayAuto;

          a.displayAuto =
            a.background +
            " " +
            a.responsibility +
            " " +
            a.actionResult +
            " " +
            a.measurement;

          if (setCustomToAuto) {
            a.displayCustom = a.displayAuto;
          }
        });
      },
    },
  },
  methods: {
    acceptAiSuggestion(item, newPhrasing) {
      log.logInformation(`new description [${newPhrasing}]`);
      log.logInformation(`item [${item.displayCustom}]`);
      item.displayCustom = newPhrasing;
      this.save();
    },
    save() {
      this.achievements = this.achievements.filter(
        (a) => !disp.IsNullorWhitespace(a.displayAuto)
      );

      this.saveWork();
    },
    ...mapMutations("work", ["createEntry"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    launchDialog(id, text) {
      this.dialogDisplay = text;
      this.dialogId = id;
      this.dialog = true;
    },
    removeDialog() {
      this.removeAchievement(this.dialogId);
      this.dialog = false;
    },
    removeAchievement(id) {
      this.achievements = this.achievements.filter((item) => item.id != id);
    },
    addAchievement() {
      if (this.achievements.length < 15) {
        this.achievements.push({
          background: "",
          responsibility: "",
          actionResult: "",
          measurement: "",
          displayAuto: "",
          displayCustom: "",
          useCustom: false,
          id: uuid.gen(),
        });

        this.panel = this.achievements.length - 1;
        let navObj = {
          path: this.$router.currentRoute.path,
          hash: "panel-" + (this.achievements.length - 1),
        };
        this.$router.push(navObj);
      }
    },
    buildTitleString(item) {
      let len = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          len = 50;
          break;
        case "sm":
          len = 70;
          break;
        case "md":
          len = 80;
          break;
        case "lg":
          len = 90;
          break;
        case "xl":
          len = 120;
          break;
      }

      return disp.Ellipsis(
        item.useCustom ? item.displayCustom : item.displayAuto,
        len
      );
    },
  },
  computed: {
    ...mapGetters("work", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      companyName: "workGeneral.companyName",
      achievements: "achievementList",
    }),
    nextti() {
      return "20"; //todo: Fix me
    },
    tips() {
      return [
        "You can put as many as 15 accomplishments per role. This is great for keeping your accomplishment list up to date.",
        "Resumation Plus users can choose whether or not to include accomplishment content by using the checkboxes in each accomplishment header, or by using the ‘Customization’ workflow later on in the process.",
        "You can select and deselect what Accomplishment you want to appear on you resume or use the Customization workflow later in the tool!",
      ];
    },
  },
};
</script>
